import './styles.scss';

import { Col, Grid, Row } from 'antd';
import accessoriesDetergentsImage from 'assets/img/acc-detergents-image.webp';
import detergentsImage from 'assets/img/detergents-image.webp';
import sparePartsImage from 'assets/img/spare-parts.webp';
import B2becAnchor from 'components/B2becAnchor';
import B2becSpareParts from 'components/B2becSpareParts';
import B2becSpinner from 'components/B2becSpinner';
import B2BecLink from 'components/B2BLink';
import ImageWithFallBack from 'components/ImageWithFallback';
import { NotificationManager } from 'components/Notification';
import useAsync from 'hooks/useAsync';
import { ASYNC_STATUS, STATUS_CODE } from 'libs/constants';
import PRODUCT_TYPES from 'libs/constants/productTypes';
import { isEmptyArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { ProductService } from 'services';
import {
  selectApplications,
  selectDataToRenderAnchor,
  selectDocuments,
  selectEquipments,
  selectHasAccessories,
  selectHasDetergents,
  selectHasSuitableProducts,
  selectLongDescription,
  selectTechnicalData,
  selectType,
} from 'store/selectors/productSelector';
import { selectShowSparePartLink } from 'store/selectors/userSelector';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../libs/constants/modulerights';
import AccessoriesDetergents from '../AccessoriesDetergents';
import ApplicationArea from '../ApplicationArea';
import CompatibleMachines from '../CompatibleMachines';
import Equipment from '../Equipment';
import ProductDescription from '../ProductDescription';
import ProductDocuments from '../ProductDocuments';
import TechnicalData from '../TechnicalData';
import useCalculateScrollbarWidth from 'hooks/useCalculateScrollbarWidth';

const entriesToCheck = {
  longDescription: 'longDescription',
  equipments: 'equipments',
  technicalData: 'technicalData',
  applications: 'applications',
  documents: 'documents',
};

const anchorLinks = [
  {
    key: 'description',
    translationKey: 'productDetails.description',
    productDetailDataKey: 'longDescription',
  },
  {
    key: 'equipments',
    translationKey: 'productDetails.equipment',
    productDetailDataKey: 'equipments',
  },
  {
    key: 'technicalData',
    translationKey: 'productDetails.technicalData',
    productDetailDataKey: 'technicalData',
  },
  {
    key: 'applications',
    translationKey: 'productDetails.applicationAreas',
    productDetailDataKey: 'applications',
  },
  {
    key: 'documents',
    translationKey: 'productDetails.documentSection.title',
    productDetailDataKey: 'documents',
  },
];

const ProductDetail = (props) => {
  const { isLoading, materialNumber } = props;

  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();
  useCalculateScrollbarWidth();

  const shouldShowSparePart = useSelector(selectShowSparePartLink);
  const longDescription = useSelector(selectLongDescription);
  const type = useSelector(selectType);
  const hasDetergents = useSelector(selectHasDetergents);
  const hasAccessories = useSelector(selectHasAccessories);
  const hasSuitableProducts = useSelector(selectHasSuitableProducts);
  const technicalData = useSelector(selectTechnicalData);
  const equipments = useSelector(selectEquipments);
  const applications = useSelector(selectApplications);
  const documents = useSelector(selectDocuments);
  const dataToRenderAnchor = useSelector(selectDataToRenderAnchor);

  const fieldsHasEmptyElements = useMemo(() => {
    const mappedEmptyProps = new Map();

    // Use entriesToCheck in order to avoid un-needed properties loop
    Object.entries(entriesToCheck).forEach(([key]) => {
      const dataByKey = dataToRenderAnchor[key];

      if (
        // For properties have value as an empty array
        isEmptyArray(dataByKey) ||
        // For properties have value as an empty string
        (typeof dataByKey === 'string' && dataByKey.length === 0)
      ) {
        mappedEmptyProps.set(key, true);
      }
    });

    return mappedEmptyProps;
  }, [dataToRenderAnchor]);

  const listKeys = anchorLinks
    .map((anchor) => {
      if (!fieldsHasEmptyElements.get(anchor.productDetailDataKey)) {
        return {
          key: anchor.key,
          translationKey: anchor.translationKey,
        };
      }
      return null;
    })
    .filter((element) => !!element);

  const renderLoadingState = () => (
    <div className="product-details__content mb-6">
      {!md && (
        <Skeleton
          className="product-details__anchor-button-wrapper"
          height={50}
        />
      )}
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="product-description__wrapper mt-4 mb-4 pr-lg-7">
            <p style={{ marginBottom: 20 }}>
              <Skeleton width={100} height={20} />
            </p>
            <Skeleton count={20} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="product-description__wrapper mt-4 mb-4 pr-lg-7">
            <p style={{ marginBottom: 20 }}>
              <Skeleton width={100} height={20} />
            </p>
            <Skeleton count={20} />
          </div>
        </Col>
      </Row>
    </div>
  );

  const { execute, status } = useAsync(
    () => ProductService.getProductSpareParts(materialNumber),
    false
  );

  const onRedirectToSpareParts = useCallback(() => {
    execute().then(({ response, error }) => {
      if (response?.status === STATUS_CODE.SUCCESS) {
        window.open(response?.data, '_blank');
      }
      if (error) {
        NotificationManager.error({
          message: 'notification.error.getProductSpareParts',
          description: error?.response?.data,
        });
      }
    });
  }, [execute]);

  const renderJumperItem = (name, imageSrc, link) => {
    const jumperItem = (
      <>
        <div className="product-details__jumper__item__image mb-3">
          <ImageWithFallBack src={imageSrc} />
        </div>
        {name}
      </>
    );
    return link?.isSparePart ? (
      <div
        className="product-details__jumper__item mr-5"
        onClick={onRedirectToSpareParts}
      >
        {status === ASYNC_STATUS.PENDING && (
          <B2becSpinner isLoading={status === ASYNC_STATUS.PENDING} />
        )}
        {jumperItem}
      </div>
    ) : (
      <B2BecLink
        className="product-details__jumper__item mr-5"
        to={link.linkTo}
      >
        {jumperItem}
      </B2BecLink>
    );
  };

  const renderJumper = () => {
    return (
      <div className="product-details__jumper__wrapper">
        <div className="product-details__jumper__title">
          {t('productDetails.findMatchingProducts.title')}
        </div>
        <div className="product-details__jumper__item__wrapper">
          {renderJumperItem(
            t('productDetails.findMatchingProducts.accessories'),
            accessoriesDetergentsImage,
            {
              linkTo: `/product/${materialNumber}/accessories`,
            }
          )}
          {renderJumperItem(
            t('productDetails.findMatchingProducts.detergents'),
            detergentsImage,
            {
              linkTo: `/product/${materialNumber}/detergents`,
            }
          )}
          {renderJumperItem(
            t('productDetails.findMatchingProducts.spareParts'),
            sparePartsImage,
            {
              isSparePart: true,
            }
          )}
        </div>
      </div>
    );
  };

  const renderDetailSectionMobileView = () => (
    <div>
      {!fieldsHasEmptyElements.get(entriesToCheck.longDescription) && (
        <ProductDescription
          anchorId={anchorLinks[0].key}
          longDescription={longDescription}
        />
      )}

      {!fieldsHasEmptyElements.get(entriesToCheck.equipments) && (
        <Equipment anchorId={anchorLinks[1].key} equipments={equipments} />
      )}

      {!fieldsHasEmptyElements.get(entriesToCheck.technicalData) && (
        <TechnicalData
          anchorId={anchorLinks[2].key}
          technicalData={technicalData}
        />
      )}

      {!fieldsHasEmptyElements.get(entriesToCheck.applications) && (
        <ApplicationArea
          anchorId={anchorLinks[3].key}
          applications={applications}
        />
      )}

      {!fieldsHasEmptyElements.get(entriesToCheck.documents) && (
        <ProductDocuments anchorId={anchorLinks[4].key} documents={documents} />
      )}
    </div>
  );

  const renderDetailSectionDesktopView = () => (
    <Row gutter={24}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {!fieldsHasEmptyElements.get(entriesToCheck.longDescription) && (
          <ProductDescription longDescription={longDescription} />
        )}
        {!fieldsHasEmptyElements.get(entriesToCheck.applications) && (
          <ApplicationArea applications={applications} />
        )}
        {!fieldsHasEmptyElements.get(entriesToCheck.documents) && (
          <ProductDocuments documents={documents} />
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {!fieldsHasEmptyElements.get(entriesToCheck.equipments) && (
          <Equipment equipments={equipments} />
        )}
        {!fieldsHasEmptyElements.get(entriesToCheck.technicalData) && (
          <TechnicalData technicalData={technicalData} />
        )}
      </Col>
    </Row>
  );

  return isLoading ? (
    renderLoadingState()
  ) : (
    <div className="product-details__content-wrapper">
      {!md && renderJumper()}
      {!md && (
        <div className="product-details__anchor-button-wrapper">
          <B2becAnchor listKeys={listKeys} anchorOffset={80} />
        </div>
      )}
      <div className="product-details__content mb-6 fadeIn-1">
        {md
          ? renderDetailSectionDesktopView()
          : renderDetailSectionMobileView()}
      </div>

      {(hasDetergents || hasAccessories) && (
        <div className="full-width-page-component__wrapper product-details__content__background mb-6">
          <div className="product-details__title__container">
            <AccessoriesDetergents
              materialNumber={materialNumber}
              options={{
                showAccessoriesButton: hasAccessories,
                showDetergentsButton: hasDetergents,
              }}
            />
          </div>
        </div>
      )}

      {hasSuitableProducts && (
        <div className="full-width-page-component__wrapper product-details__content__background mb-6 pt-6 pb-6">
          <div className="product-details__title__container">
            <CompatibleMachines materialNumber={materialNumber} />
          </div>
        </div>
      )}

      {shouldShowSparePart && type !== PRODUCT_TYPES.DETERGENT ? (
        <PermissionWrapper
          permission={MODULE_SHOP_NAVIGATION_RIGHTS.OPEN_SPARE_PART_SHOP}
        >
          <div
            className="full-width-page-component__wrapper product-details__content__background pt-6 pb-6"
            style={{ marginBottom: 28 }}
          >
            <B2becSpareParts
              className="spare-parts product-details--padding-left product-details--padding-right"
              materialNumber={materialNumber}
              translation={{ allSparePart: 'allSparePartOfMachine' }}
            />
          </div>
        </PermissionWrapper>
      ) : null}
    </div>
  );
};

ProductDetail.propTypes = {
  isLoading: PropTypes.bool,
  materialNumber: PropTypes.string,
};
ProductDetail.defaultProps = {
  isLoading: true,
  materialNumber: '',
};

export default ProductDetail;
